export const IMAGES_SEGMENTS = {
  AVATARS: 'avatars',
  GAMES: 'games',
  COMPETITIONS: 'competitions',
  PLATFORMS: 'platforms',
  SPONSOR: 'sponsor',
  PRODUCTS_BACKGROUNDS: 'products/backgrounds',
  LEAGUES: 'leagues',
  LEAGUES_TEAMS: 'leagues_teams',
  CHALLENGES: 'challenges',
  AWARDS: 'awards',
  PROMOTIONS: 'promotions',
};
