
  import Vue, {PropType} from "vue";
  import {Location} from "vue-router";

  export default Vue.extend({
    name: "pwa-card",
    props: {
      title: {
        type: String,
        required: true,
      },
      description: {
        type: String,
        required: true,
      },
      imageSrc: {
        type: String,
        required: true,
      },
      buttonLabel: {
        type: String,
        required: false,
        default: null,
      },
      imageAlt: {
        type: String,
        required: false,
        default: '',
      },
      to: {
        type: [String,Object] as PropType<string|Location>,
        required: false,
        default: null,
      },
    },
    computed: {
      isExternalUrl(): boolean {
        return typeof this.to === 'string' && this.to.charAt(0) !== '/';
      },
    },
  });
