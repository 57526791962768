
  import Vue from "vue";
  import pageMixin from "@/mixins/page";
  import {BreadcrumbItems, BreadcrumbItemType} from "@/utils/breadcrumb";
  import PwaCard from "@/components/PwaCard.vue";
  import { ROUTES } from '@/router/routes';
  import {IMAGES_SEGMENTS} from "@/utils/images";
  import { isPrerender } from '@/utils/common';
  import { MetaInfo } from 'vue-meta';

  const pageLimit = 9;

  export default Vue.extend({
    name: "Promotions",
    mixins: [pageMixin],
    components: {
      PwaCard,
      PwaFooter: () => import('@/components/layout/PwaFooter.vue'),
    },
    data() {
      return {
        promotionsList: this.getPageInitialData('promotionsList', []),
        hasMorePages: false,
        routes: ROUTES,
        imagesSegments: IMAGES_SEGMENTS,
      };
    },
    mounted(): void {
      this.getPromotionsPage(true);
      if (!isPrerender) {
        this.removePageInitialData();
      }
    },
    computed: {
      breadcrumb(): BreadcrumbItemType[] {
        return [{labelId: BreadcrumbItems.promotions.labelId}];
      },
      metaData(): MetaInfo {
        return {
          title: this.$t('776'),
          meta: [
            {name: 'description', content: this.$t('775')},
            {name: 'robots', content: 'index'},
          ],
          link: this.getMetaDataPathLinks(ROUTES.promotions.path),
        };
      },
    },
    methods: {
      async getPromotionsPage(reset = false) {
        try {
          this.showLoader(true);
          const params = {
            offset: this.promotionsList.length,
            limit: pageLimit,
          };
          const {data} = await this.$http.page.getPromotionsPage(params);
          const promotionsList = data.data.promotions || [];
          if (!reset && this.promotionsList.length > 0) {
            this.promotionsList = [...this.promotionsList, ...promotionsList];
          } else {
            this.promotionsList = promotionsList;
          }
          this.hasMorePages = promotionsList.length >= pageLimit;
          if (isPrerender) {
            this.savePageInitialData({promotionsList: promotionsList})
          }
        } catch (e) {
          this.showToastError(this.$t('139'), this.$t('140'));
        } finally {
          this.showLoader(false);
        }
      },
    },
    watch: {
      currentLang() {
        this.getPromotionsPage(true);
      }
    }
  });
